.container {
    max-width: 1200px;
    margin: 0 auto 20px ;
    padding:1.5rem  1rem;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .imageWrapper {
    aspect-ratio: 1 / 1;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 768px) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
      width:90%;
      justify-content: center;
      margin:2% auto;
    }
  }