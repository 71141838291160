.loadingScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
}
.load{
    font-size: 1.1rem;
    font-family: "AssistantR";
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 60%;
    color: #333;  
}
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(255, 255, 255);
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}