.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }
  
  .center {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  
  .button {
    background: linear-gradient(to right, #c99f78, #F0D0B3);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Assistant', sans-serif;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popupContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    direction: rtl;
    font-family: 'Assistant', sans-serif;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  
  .h2, .h3 {
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
  }
  
  .p, .li {
    font-family: 'AssistantR', 'Assistant', sans-serif;
    font-weight: 400;
  }
  
  .ul {
    padding-right: 20px;
    padding-left: 0;
  }
  
  /* Responsive styles */
  
  @media (max-width: 350px) {
    .button {
      font-size: 14px;
      padding: 10px 20px;
    }
    .popupContent {
      max-width: 85%;
      padding: 10px;
    }
    .h2 { font-size: 18px; }
    .h3 { font-size: 16px; }
    .p, .li { font-size: 14px; }
  }
  
  @media (min-width: 350px) and (max-width: 450px) {
    .button {
      font-size: 14px;
      padding: 12px 24px;
    }
    .popupContent {
      max-width: 90%;
      padding: 15px;
    }
    .h2 { font-size: 20px; }
    .h3 { font-size: 18px; }
    .p, .li { font-size: 13px; }
  }
  
  @media (min-width: 450px) and (max-width: 550px) {
    .button {
      font-size: 15px;
      padding: 13px 26px;
    }
    .popupContent {
      max-width: 85%;
    }
    .h2 { font-size: 22px; }
    .h3 { font-size: 20px; }
    .p, .li { font-size: 14px; }
  }
  
  @media (min-width: 550px) and (max-width: 650px) {
    .popupContent {
      max-width: 80%;
    }
    .h2 { font-size: 24px; }
    .h3 { font-size: 22px; }
    .p, .li { font-size: 15px; }
  }
  
  @media (min-width: 650px) and (max-width: 750px) {
    .popupContent {
      max-width: 75%;
    }
  }
  
  @media (min-width: 750px) and (max-width: 850px) {
    .popupContent {
      max-width: 70%;
    }
  }
  
  @media (min-width: 850px) and (max-width: 950px) {
    .popupContent {
      max-width: 65%;
    }
  }
  
  @media (min-width: 950px) and (max-width: 1050px) {
    .popupContent {
      max-width: 60%;
    }
  }
  
  @media (min-width: 1050px) and (max-width: 1150px) {
    .popupContent {
      max-width: 55%;
    }
  }
  
  @media (min-width: 1150px) and (max-width: 1250px) {
    .popupContent {
      max-width: 50%;
    }
  }
  
  @media (min-width: 1250px) {
    .popupContent {
      max-width: 45%;
    }
  }