.confettiContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
  }
  
  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .title, .titleP {
    font-size: 4rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    color: #8B4513;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    direction: rtl;
    background: linear-gradient(45deg, #D2691E, #8B4513);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
  }
  
  .title::after, .titleP::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 3px;
    background: linear-gradient(90deg, transparent, #8B4513, transparent);
  }
  
  .titleP {
    padding-top: 80px;
  }
  
  .description {
    font-size: 1.5rem;
    font-family: "AssistantR", sans-serif;
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 60%;
    color: #333;
  }
  
  .center {
    width: 30%;
    justify-content: center;
    margin: 3% auto;
  }
  
  .image {
    width: 100%;
    object-fit: contain;
  }
  
  @media (max-width: 350px) {
    .title, .titleP {
      font-size: 2rem;
    }
    .description {
      font-size: 1.1rem;
      width: 95%;
    }
    .center {
      width: 70%;
    }
  }
  
  @media (min-width: 350px) and (max-width: 450px) {
    .title, .titleP {
      font-size: 2.2rem;
    }
    .description {
      font-size: 1.1rem;
      width: 85%;
    }
    .center {
      width: 75%;
    }
  }
  
  @media (min-width: 450px) and (max-width: 550px) {
    .title, .titleP {
      font-size: 2.5rem;
    }
    .description {
      font-size: 1.2rem;
      width: 80%;
    }
    .center {
      width: 65%;
    }
  }
  
  @media (min-width: 550px) and (max-width: 650px) {
    .title, .titleP {
      font-size: 2.7rem;
    }
    .description {
      font-size: 1.25rem;
      width: 75%;
    }
    .center {
      width: 55%;
    }
  }
  
  @media (min-width: 650px) and (max-width: 750px) {
    .title, .titleP {
      font-size: 2.9rem;
    }
    .description {
      font-size: 1.3rem;
      width: 70%;
    }
    .center {
      width: 55%;
    }
  }
  
  @media (min-width: 750px) and (max-width: 850px) {
    .title, .titleP {
      font-size: 3.1rem;
    }
    .description {
      font-size: 1.35rem;
      width: 65%;
    }
    .center {
      width: 45%;
    }
  }
  
  @media (min-width: 850px) and (max-width: 950px) {
    .title, .titleP {
      font-size: 3.2rem;
    }
    .description {
      font-size: 1.37rem;
      width: 60%;
    }
    .center {
      width: 40%;
    }
  }
  
  @media (min-width: 950px) and (max-width: 1050px) {
    .title, .titleP {
      font-size: 3.3rem;
    }
    .description {
      font-size: 1.38rem;
      width: 55%;
    }
    .center {
      width: 35%;
    }
  }
  
  @media (min-width: 1050px) and (max-width: 1150px) {
    .title, .titleP {
      font-size: 3.4rem;
    }
    .description {
      font-size: 1.39rem;
      width: 52%;
    }
    .center {
      width: 30%;
    }
  }