.container {
  max-width: 700px;
  margin: 4% auto;
  padding: 10px;
  font-family: "Assistant";
  direction: rtl;
}

.title {
  text-align: center;
  color: #000000;
  
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.185);
  font-size: 2.5rem;

}

.description {
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.185);
  color: #0f0600;
  font-size: 1.8rem;
 margin:0 auto;
}

.workflow {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.step {
  display: flex;
  background-color: #FFF5E6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(255, 140, 0, 0.1);
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  background-color: #8b4503;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.stepContent {
  flex: 1;
  padding: 12px;
}

.stepTitle {
  color: #8b4503;
  font-size: 18px;
  margin: 0 0 8px 0;
}

.stepDescription {
  color: #333;
  font-size: 14px;
  white-space: pre-line;
  line-height: 1.4;
  margin: 0;
}

/* Media Queries */
@media (max-width: 350px) {
  .title {
    font-size: 1.8rem;
  }
  .description {
    font-size: 1.2rem;
  }
  .stepNumber {
    width: 40px;
    font-size: 16px;
  }
  .stepTitle {
    font-size: 16px;
  }
  .stepDescription {
    font-size: 12px;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .title {
    font-size: 2rem;
  }
  .description {
    font-size: 1.4rem;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .title {
    font-size: 2.2rem;
  }
  .description {
    font-size: 1.5rem;
  }
  .stepNumber {
    width: 45px;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .title {
    font-size: 2.4rem;
  }
  .description {
    font-size: 1.6rem;
  }
  .stepNumber {
    width: 50px;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
  .title {
    font-size: 2.6rem;
  }
  .description {
    font-size: 1.7rem;
  }
  .stepNumber {
    width: 55px;
    font-size: 22px;
  }
  .stepTitle {
    font-size: 19px;
  }
  .stepDescription {
    font-size: 15px;
  }
}
