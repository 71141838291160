.button {
    font-family: "Assistant";
    font-size: 18px;
    width: 350px;
    height: 50px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 4% auto;
    border-radius: 8px;
    background:linear-gradient(to right, #F7E5D4, #F0D0B3);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #7832bd89, #f5f5f5);
  }
.center{
  display: flex;
  justify-content: center;
}
  @media only screen and (max-width: 350px) {

    .button {
      font-family: "Assistant";
      font-size: 16px;
      width: 250px;
      height: 40px;
      direction: rtl;
      border: none;
      cursor: pointer;
      margin: 15px auto;
      border-radius: 8px;
      background:linear-gradient(to right, #F7E5D4, #F0D0B3);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #88888869;
      transition: all 0.5s ease-in-out;
      
      padding: 0 15px;
      outline: none;
      color: #333;
    }
    .button:hover {
  transform: scale(1.1);
    }
    .button:focus {
      box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
      background: linear-gradient(to right, #7832bd89, #f5f5f5);
    }
  


  }

  
  @media only screen  and (min-width: 350px) and (max-width: 450px) {

    .button {
      font-family: "Assistant";
      font-size: 16px;
      width: 90vw;
      height: 40px;
      direction: rtl;
      border: none;
      cursor: pointer;
      margin: 3% auto;
      border-radius: 8px;
      background:linear-gradient(to right, #F7E5D4, #F0D0B3);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
      transition: all 0.5s ease-in-out;
      
      padding: 0 15px;
      outline: none;
      color: #333;
    }
    .button:hover {
  transform: scale(1.1);
    }
    .button:focus {
      box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
      background: linear-gradient(to right, #7832bd89, #f5f5f5);
    }

  }
  @media only screen  and (min-width: 450px) and (max-width: 550px) {

    .button {
      font-family: "Assistant";
      font-size: 18px;
      width: 80vw;
      height: 50px;
      direction: rtl;
      border: none;
      cursor: pointer;
      margin: 3% auto;
      border-radius: 8px;
      background:linear-gradient(to right, #F7E5D4, #F0D0B3);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
      transition: all 0.5s ease-in-out;
      
      padding: 0 15px;
      outline: none;
      color: #333;
    }
    .button:hover {
  transform: scale(1.1);
    }
    .button:focus {
      box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
      background: linear-gradient(to right, #7832bd89, #f5f5f5);
    }

  }
  @media only screen  and (min-width: 550px) and (max-width: 650px) {

    .button {
      font-family: "Assistant";
      font-size: 18px;
      width: 70vw;
      height: 50px;
      direction: rtl;
      border: none;
      cursor: pointer;
      margin: 3% auto;
      border-radius: 8px;
      background:linear-gradient(to right, #F7E5D4, #F0D0B3);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
      transition: all 0.5s ease-in-out;
      
      padding: 0 15px;
      outline: none;
      color: #333;
    }
    .button:hover {
  transform: scale(1.1);
    }
    .button:focus {
      box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
      background: linear-gradient(to right, #7832bd89, #f5f5f5);
    }

  }
  @media only screen  and (min-width: 650px) and (max-width: 850px) {

    .button {
      font-family: "Assistant";
      font-size: 18px;
      width: 65vw;
      height: 50px;
      direction: rtl;
      border: none;
      cursor: pointer;
      margin: 3% auto;
      border-radius: 8px;
      background:linear-gradient(to right, #F7E5D4, #F0D0B3);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
      transition: all 0.5s ease-in-out;
      
      padding: 0 15px;
      outline: none;
      color: #333;
    }
    .button:hover {
  transform: scale(1.1);
    }
    .button:focus {
      box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
      background: linear-gradient(to right, #7832bd89, #f5f5f5);
    }

  }
  @media only screen  and (min-width: 850px) and (max-width: 1050px) {

    .button {
      font-family: "Assistant";
      font-size: 18px;
      width: 50vw;
      height: 50px;
      direction: rtl;
      border: none;
      cursor: pointer;
      margin: 3% auto;
      border-radius: 8px;
      background:linear-gradient(to right, #F7E5D4, #F0D0B3);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
      transition: all 0.5s ease-in-out;
      
      padding: 0 15px;
      outline: none;
      color: #333;
    }
    .button:hover {
  transform: scale(1.1);
    }
    .button:focus {
      box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
      background: linear-gradient(to right, #7832bd89, #f5f5f5);
    }

  }
  @media only screen  and (min-width: 1050px) and (max-width: 1250px) {

    .button {
      font-family: "Assistant";
      font-size: 18px;
      width: 45vw;
      height: 50px;
      direction: rtl;
      border: none;
      cursor: pointer;
      margin: 3% auto;
      border-radius: 8px;
      background:linear-gradient(to right, #F7E5D4, #F0D0B3);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
      transition: all 0.5s ease-in-out;
      
      padding: 0 15px;
      outline: none;
      color: #333;
    }
    .button:hover {
  transform: scale(1.1);
    }
    .button:focus {
      box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
      background: linear-gradient(to right, #7832bd89, #f5f5f5);
    }

  }