.gridContainer {
  display: grid;
  grid-template-columns: 1fr;
 
  max-width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  direction: rtl;
}

.title {
  font-size: 2rem;
  color: #000000;
  font-family: "Assistant";
  text-align: center;
margin:0 auto 1%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.mainContainer, .sideContainer {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.section {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(139, 69, 19, 0.2);
}

.section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/רקע\ כתום.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2;
  z-index: -1;
  border-radius: inherit;
}

.sectionTitle {
  font-size: 1.5rem;
  color: #8b4503;
  font-family: "Assistant";
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(139, 69, 19, 0.3);
  padding-bottom: 0.3rem;
  text-shadow: 1px 1px 1px rgba(139, 69, 19, 0.2);
}

.menuItem {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed rgba(139, 69, 19, 0.2);
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  margin-bottom: 0.3rem;
}

.itemName {
  font-family: "Assistant";
  font-size: 1rem;
  color: #4a6741;
  text-shadow: 1px 1px 1px rgba(74, 103, 65, 0.2);
}

.itemPrice {
  color: #8b4503;
  font-family: "Assistant";
  font-size: 0.9rem;
  white-space: nowrap;
}
.description {
  font-size: 0.9rem;
  color: #555555;
  margin-top: 0.3rem;
  font-style: italic;
  font-family: "AssistantR";
}
.bold {
  font-size: 1.4rem;
  color: #000000;
  margin:2% auto;
width:50%;
direction: rtl;
text-align: center;
  font-family: "AssistantR";
}
/* מתחת ל-350px */
@media (max-width: 350px) {
  .gridContainer {
    padding: 0.5rem;
  }
  .title {
    font-size: 2rem;
  }
  .sectionTitle {
    font-size: 1.2rem;
  }
  .bold {
    font-size: 1.1rem;
  width:95%;
  }
}

/* 350-450px */
@media (min-width: 350px) and (max-width: 450px) {
  .gridContainer {
    padding: 0.7rem;
  }
  .title {
    font-size: 1.7rem;
  }
  .sectionTitle {
    font-size: 1.3rem;
  }
  .bold {
    font-size: 1.15rem;
  width:90%;
  }
}

/* 450-550px */
@media (min-width: 450px) and (max-width: 850px) {
  .gridContainer {
    padding: 0.9rem;
  }
  .title {
    font-size: 1.9rem;
  }
  .sectionTitle {
    font-size: 1.4rem;
  }
  .bold {
    font-size: 1.2rem;
  width:70%;
  }
}



/* 850-950px */
@media (min-width: 850px) and (max-width: 950px) {
  .gridContainer {
    padding: 1.6rem;
    grid-template-columns: 2fr 1fr;
  }
  .title {
    font-size: 2.7rem;
    grid-column: 1 / -1;
  }
  .sectionTitle {
    font-size: 1.8rem;
  }
  .mainContainer {
    grid-column: 1 / 2;
  }
  .sideContainer {
    grid-column: 2 / 3;
  }
  .section{
    margin:0.5rem
  }
  .bold {
    font-size: 1.25rem;
  width:60%;
  }
}

/* 950-1050px */
@media (min-width: 950px) and (max-width: 1050px) {
  .gridContainer {
    padding: 1.8rem;
    grid-template-columns: 2fr 1fr;
    max-width: 950px;
  }
  .title {
    font-size: 2.8rem;
    grid-column: 1 / -1;
  }
  .sectionTitle {
    font-size: 1.9rem;
  }
  .section{
    margin:0.5rem
  }
}

/* 1050-1150px */
@media (min-width: 1050px) and (max-width: 1150px) {
  .gridContainer {
    padding: 2rem;
    grid-template-columns: 2fr 1fr;
    max-width: 1050px;
  }
  .title {
    font-size: 2.9rem;
    grid-column: 1 / -1;
  }
  .sectionTitle {
    font-size: 2rem;
  }
  .section{
    margin:0.5rem
  }
}

/* 1150-1250px */
@media (min-width: 1150px) and (max-width: 1250px) {
  .gridContainer {
    padding: 2.2rem;
    grid-template-columns: 2fr 1fr;
    max-width: 1150px;
  }
  .title {
    font-size: 3rem;
    grid-column: 1 / -1;
  }
  .sectionTitle {
    font-size: 2.1rem;
  }
  .section{
    margin:0.5rem
  }
}

/* מעל 1250px */
@media (min-width: 1250px) {
  .gridContainer {
    padding: 2.4rem;
    grid-template-columns: 2fr 1fr;
    max-width: 1250px;
  }
  .title {
    font-size: 3.2rem;
    grid-column: 1 / -1;
  }
  .sectionTitle {
    font-size: 2.2rem;
  }
  .section{
    margin:0.5rem
  }
}